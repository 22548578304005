import '@babel/polyfill'
import 'mutationobserver-shim'
import Raphael from 'raphael/raphael'
global.Raphael = Raphael
import Vue from 'vue'
import './plugins'
import App from './App.vue'
import router from './router'
import store from './store'
import './directives'
import 'leaflet/dist/leaflet.css';

import ApolloClient from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'


import moment from 'moment'
import { BVToastPlugin, FormDatepickerPlugin } from 'bootstrap-vue'
// import VueMaterial from 'vue-material'
// import 'vue-material/dist/vue-material.min.css'
// import 'vue-material/dist/theme/default.css'


import VueSingleSelect from "vue-single-select"
import Multiselect from 'vue-multiselect'
import * as VueGoogleMaps from "vue2-google-maps"
import datetimePicker from 'vue-bootstrap-datetimepicker';
import 'vuejs-google-maps/dist/vuejs-google-maps.css'
import 'vue2-timepicker/dist/VueTimepicker.css'

Vue.config.productionTip = false
Vue.use(VueApollo);

Vue.component('vue-single-select', VueSingleSelect);
Vue.component('multiselect', Multiselect);
Vue.use(BVToastPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(datetimePicker);


Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBYNH0DMSgv49sMRwxGwphI4DGxger5PwM",
    libraries: ["places"]
    // "places"
  }
});


//Format for Date
Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD MMMM YYYY hh:mm A')
  }
});

//Format for Date
Vue.filter('dateDayFormat', function (value) {
  if (value) {
    return moment(String(value)).format('ddd, DD MMM')
  }
});

//Format for Date
Vue.filter('timeFormat', function (value) {
  if (value) {
    return moment(String(value)).format('hh:mm A')
  }
});

// Format for Time (convert 24hrs to 12hrs)
Vue.filter('formatTime', function(value) {
  if(value){
    const timeString12hr = new Date('1970-01-01T' + value + 'Z')
      .toLocaleTimeString('en-US',
        { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }
      );
    return timeString12hr
  }
})

//Convert SERV_NAME to Title Case
Vue.filter('customTitleCase', function (value) {
  if (value) {
    return value.replace("_", " ").replace(/\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }
});


const getHeaders = () => {
  return router.headers
}

const link = new HttpLink({
  uri: "https://test.mysyara.com/graphql/",
  fetch,
  headers: getHeaders(),
  credentials: "omit",
})

// const defaultOptions = {
//   watchQuery: {
//     fetchPolicy: 'cache-and-network',
//     errorPolicy: 'ignore',
//   },
//   query: {
//     fetchPolicy: 'no-cache',
//     errorPolicy: 'all',
//   },
//   // mutate: {
//   //   fetchPolicy: 'no-cache'
//   // },
// };


export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: true
  }),
  // defaultOptions: defaultOptions,
  defaultOptions:{
    query:{
      fetchPolicy:'no-cache'
    },
    // mutate:{
    //   fetchPolicy:'no-cache'
    // }
  }
  // defaultOptions: {
  //   query: {
  //     fetchPolicy: 'network-only'
  //     // fetchPolicy: 'no-cache'
  //   }

  // }

})

// client.defaultOptions.query = {
//   fetchPolicy: "no-cache"
// };

const apolloProvider = new VueApollo({
  defaultClient: client,
})

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
